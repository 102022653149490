<template>
  <a-spin :spinning="spinning">
    <a-row :gutter="8">
      <a-col
        :span="4"
        class="organization-unit-tree-panel"
        style="width: 200px; overflow-x: auto"
      >
        <a-card>
          <ul class="lr-left-list" id="lr_left_list">
            <li
              v-for="(item, index) in todos"
              @click="initleft(item)"
              class="lrlg"
              :class="{ active: item.active }"
              :key="index"
              :data-value="item.value"
            >
              {{ item.text }}
            </li>
          </ul>
        </a-card>
      </a-col>
      <a-col
        :span="18"
        class="organization-unit-tree-panel"
        style="width: calc(100% - 200px)"
      >
        


        <!-- table -->
          <!-- <react-table
            :table-data="tableData"
            :total-items="totalItems"
            :showTotal="showTotalFun"
            :actions-type="actionsType"
            :columns="columns"
            :hide-row-selection="true"
            :scroll="{x: 0}"
            :is-full="true"
            :current-page="pageNumber"
            @emitRefreshData="clearFilterAndRefresh"
            @emitSortData="updateSortData"
            @emitSelectChange="updateSelectChange"
            @emitOnPageChange="updatePageChange"
            @emitShowSizeChange="updateShowSizeChange"
          > -->
          <ele-table
            :columns="columns"
            :table-data="tableData"
            :total-items="totalItems"
            :is-full="true"
            :actions-type="actionsType"
            :current-page="pageNumber"
            :hide-row-selection="true"
            @emitRefreshData="clearFilterAndRefresh"
            @emitSelectChange="updateSelectChange"
            @emitOnPageChange="updatePageChange"
            @emitShowSizeChange="updateShowSizeChange"
          >
            <a-row :gutter="8">
              <a-col :span="6" class="gutter-row">
                <a-input
                  :placeholder="l('SearchWithThreeDot')"
                  v-model.trim="filterText"
                />
              </a-col>

              <a-col :span="4" class="gutter-row" >
                <a-button @click="search" type="primary" v-if="isGranted('Pages.WorkflowWatch.Query')">
                  {{ l("Search") }}
                </a-button>
                <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
              </a-col>
            </a-row>
          </ele-table>
      </a-col>
    </a-row>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";
import EleTable from "@/components/ele-table";
import ReactModify from "@/components/react-modify";
import { CommonServiceProxy, initLevel, initEnableMark } from "@/shared/common-service";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";


import Vue from "vue";
import form from "./form.vue";

export default {
  name: "provide",
  mixins: [AppComponentBase],
  props: ["id", "reqUrl", "tableKey"],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      commonService: null,
      todos: [
        { text: "未完成", value: 1, active: true },
        { text: "已完成", value: 2 },
      ],
      selectedRowKeys: [],
      name: "",
      filterText: "",
      categoryId: "1",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { sorting: "", maxResultCount: 30, skipCount: 0 },
      // 用户表格
      columns: [
        {
          title: this.l("标题"),
          dataIndex: "title",
          sorter: true,
          align: "center",
          scopedSlots: { customRender: "title" },
        },
        {
          title: this.l("等级"),
          dataIndex: "level",
          sorter: true,
          align: "center",
          customRender: function (text, record, index) {
            if (text) {
              switch (text) {
                case 0:
                  return "普通";
                  break;
                case 1:
                  return "重要";
                  break;
                case 2:
                  return "紧急";
                  break;
                default:
                  return "普通";
                  break;
              }
            }
            return "";
          },
          scopedSlots: { customRender: "level" },
        },
        {
          title: this.l("状态"),
          dataIndex: "enabledMark",
          sorter: true,
          align: "center",
          // fixed: "right",
          customRender: function (text, record, index) {
            console.log(text);
            console.log(record);
            if (record.taskType == 4) {
              if (record.isFinished == 0) {
                if (record.isUrge == "1" && categoryId == "2") {
                  return "催办加急";
                }
                return "运行中";
              } else {
                return "结束";
              }
            }
            if (record.isFinished == 0) {
              if (text == 1) {
                if (record.isUrge == "1" && categoryId == "2") {
                  return "催办加急";
                }
                return "运行中";
              } else if (text == 2) {
                return "草稿";
              } else {
                return "作废";
              }
            } else {
              return "结束";
            }
          },
          scopedSlots: { customRender: "enabledMark" },
        },
        {
          title: this.l("发起者"),
          dataIndex: "createUserName",
          sorter: true,
          align: "center",
          // fixed: "right",
          scopedSlots: { customRender: "createUserName" },
        },
        {
          title: this.l("时间"),
          dataIndex: "createDate",
          sorter: true,
          align: "center",
          // fixed: "right",
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "createDate" },
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          sorter: true,
          align: "center",
          fixed: "right",
          scopedSlots: { customRender: "actions" },
        },
      ],
      // 用户数据
      tableData: [],
      // btns
      actionsType: {},
      // 保存页面参数中介
      getDataParams: [],
    };
  },
  computed: {},
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initActionsType();
    this.getData();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    search() {
      this.pageNumber = 1
      this.request.skipCount = 0
      this.getData()
    },
    // 拉取数据
    async getData(opt) {
      this.spinning = true;
      let params = {
        filterText: this.filterText,
        sorting: this.request.sorting,
        skipCount: this.request.skipCount,
        maxResultCount: this.request.maxResultCount,
        pageNumber: this.pageNumber,
        categoryId: this.categoryId,
      };
      if (opt && opt.length) {
        if (!this.getDataParams.length) this.getDataParams = opt;
        opt.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      } else if (this.getDataParams && this.getDataParams.length) {
        this.getDataParams.map((it) => {
          if (it.key && it.value) {
            params[it.key] = it.value;
          }
        });
      }
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/WorkflowTask/GetMonitorPageList",
          params: params,
        });
        this.spinning = false;
        this.tableData = res.items.map((o) => {
          return {
            ...o,
            isDefault: o.isDefault ? "是" : "否",
            createDate: moment(o.createDate).format("YYYY-MM-DD"),
            level: initLevel(o.returnLevel),
            enabledMark: initEnableMark(o, o.enabledMark, this.categoryId),
          };
        });
        this.totalItems = res.totalCount;
        this.pagerange = [
          (this.pageNumber - 1) * this.request.maxResultCount + 1,
          this.pageNumber * this.request.maxResultCount,
        ];
        this.totalPages = Math.ceil(
          res.totalCount / this.request.maxResultCount
        );
      } catch (error) {
        this.spinning = false;
      }
    },
    initleft(item) {
      this.$nextTick(function () {
        this.todos.forEach(function (item) {
          Vue.set(item, "active", false);
        });
        Vue.set(item, "active", true);
        this.categoryId = item.value;
        this.getData();
      });
    },
    // 初始化需要显示的func btn
    initActionsType() {
      let _this = this;
      let obj = {
        type: "provide",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: true,
              name: this.l("查看"),
              icon: "edit",
              fn: (data) => {
                _this.openForm(data, "preview");
                // _this.createOrEdit(newV.item, 'preview');
                // _this.createOrEdit(newV.item, 'create');
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    /**
     * 新建修改
     */
    openForm(param) {
      console.log("openForm");
      let mode = "create";
      if (param != "") {
        mode = "edit";
      } else {
      }
      ModalHelper.create(
        form,
        {
          mode: mode,
          formModel: {
            ...param,
          },
        },
        {
          width: "1200px",
          isChange: true,
        }
      ).subscribe((res) => {
        console.log("messageRevecived");
        console.log(res);
        this.refreshGoFirstPage();
      });
    },
    // 删除
    async deleteItem(id) {
      // let { id } = newV.item;
      this.spinning = true;
      try {
        let res = await this.commonService.delete({
          url:
            "/api/services/app/CustomerProvideConfig/DeleteCustomerProvideConfig",
          params: id,
        });
        this.spinning = false;
        this.getData();
        this.$notification["success"]({
          message: this.l("SuccessfullyRemoved"),
        });
        this.clearFilterAndRefresh();
      } catch (error) {
        this.spinning = false;
      }
    },
    changeDefault(item) {
      // let { item } = newV
      let _this = this;
      this.$confirm({
        title: "默认设置",
        content: "此项设为默认?",
        okText: "确定",
        cancelText: "取消",
        async onOk() {
          // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          try {
            let res = await _this.commonService.post({
              url: "/api/services/app/CustomerProvideConfig/ChangeDefault",
              params: { id: item.id },
            });
            _this.getData();
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // 分页
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.getData();
    },
    // table选择事件
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 清除条件并刷新
    clearFilterAndRefresh() {
      this.request = { sorting: "", maxResultCount: 30, skipCount: 0 };
      this.name = "";
      this.filterText = "";
      this.getData();
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      this.request.skipCount = 0;
      this.getData();
    },
    // 分页事件
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    // 导出
    async exportExcel() {
      this.spinning = true;
      try {
        const res = await this.commonService.get({
          url: "/api/services/app/CustomerProvideConfig/GetToExcelFile",
          params: {},
        });
        fileDownloadService.downloadTempFile(result);
      } catch (error) {
        this.spinning = false;
      }
    },
    // 增加修改查看
    createOrEdit(item, type) {
      const list = [
        {
          label: "appId",
          value: "appId",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [],
          changeFn: () => {},
        },
        {
          label: "privateKey",
          value: "privateKey",
          type: "textarea",
          disabled: false,
          required: true,
          rules: [],
          changeFn: () => {},
        },
        {
          label: "名称",
          value: "name",
          type: "commonInput",
          disabled: false,
          required: true,
          rules: [],
          changeFn: () => {},
        },
      ];
      let reqParams = [];
      if (item && item.id) reqParams.push({ key: "id", value: item.id });
      let otherPrams = {};
      ModalHelper.create(
        ReactModify,
        {
          params: {
            type: type, // edit,preview,create
            data: list,
            reqParams: [...reqParams, { key: "customerId", value: this.id }],
            reqUrl: {
              getById: "/api/services/app/CustomerProvideConfig/GetEditById",
              save:
                "/api/services/app/CustomerProvideConfig/SaveCustomerProvideConfig",
            },
            otherParams: {
              isDefault: this.tableData.length,
            },
            getItemfn: async (res, form, options) => {
              form.setFieldsValue({ ...res });
              if (options) {
                // ...
              }
            },
            submitfn: async (values, queryParams) => {
              let obj = {};
              queryParams.reqParams.map((item) => {
                obj[item.key] = item.value;
              });
              Object.assign(values, obj);
              // console.log(values)
              try {
                let res = await this.commonService.post({
                  url: queryParams.reqUrl.save,
                  params: {
                    ...values,
                    isDefault: queryParams.otherParams.isDefault ? false : true,
                  },
                });
                return true;
              } catch (error) {
                return false;
              }
            },
          },
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.clearFilterAndRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.pagination {
  margin: 10px auto;
  text-align: right;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lr-left-list > li {
  position: relative;
  width: 100%;
  height: 34px;
  line-height: 34px;
  margin-bottom: 10px;
  padding-left: 20px;
  cursor: pointer;
  border-left: 3px solid #fff;
}
.lr-left-list > li:hover {
  background-color: #f5f5f5;
  border-left: 3px solid #f5f5f5;
}
.lr-left-list > li.active {
  background-color: #f5f5f5;
  border-left: 3px solid #2c3849;
}
</style>
